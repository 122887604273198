import { Flex, Box, Text } from '@chakra-ui/react';
import { NextPage } from 'next';
import React from 'react';

const NotFoundPage: NextPage = () => {
  return (
    <Flex
      w="100%"
      h="calc(100vh)"
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Flex direction="column" align="center">
        <Text
          fontSize="xxx-large"
          fontWeight="bold"
          textAlign="center"
          color="brandRed"
        >
          404
        </Text>
        <Text fontSize="xxx-large" fontWeight="bold" textAlign="center">
          Not Found
        </Text>
      </Flex>
    </Flex>
  );
};

export default NotFoundPage;
